import classNames from 'classnames'
import React, { Component } from 'react'

import SwitchInput from '~/components/switch-input'

import type { ReactNode } from 'react'

import './checkbox.scss'

export type Props = {
  type?: 'switch'
  checked?: boolean
  onChange: (arg0: boolean) => void
  label?: ReactNode
  description?: ReactNode
  hasError?: boolean
  className?: string
  children?: ReactNode
  id?: string
  disabled?: boolean
  readOnly?: boolean
  labelFirst?: boolean
}

export default class CheckboxInput extends Component<Props> {
  static defaultProps = {
    readOnly: false,
    labelFirst: false,
  }

  onChange = (state: boolean) => {
    const { disabled, onChange } = this.props

    if (!disabled) {
      onChange(state)
    }
  }

  onRawChange = (e) => {
    this.onChange(e.target.checked)
  }

  renderInput() {
    const { id, type, checked, disabled, readOnly } = this.props

    if (type === 'switch') {
      return (
        <SwitchInput
          className={classNames('switch-input', {
            checked,
          })}
          disabled={disabled}
          checked={checked}
          onChange={this.onChange}
        />
      )
    }

    return (
      <input
        className={classNames('input', this.props.className)}
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={this.onRawChange}
        readOnly={readOnly}
        id={id}
      />
    )
  }

  renderLabel() {
    const { label } = this.props

    if (!label) {
      return
    }

    return <div className="label">{label}</div>
  }

  renderDescription() {
    const { description } = this.props

    if (!description) {
      return
    }

    return <div className="description">{description}</div>
  }

  render() {
    const className = classNames('amp-checkbox-input', this.props.className, {
      'has-error': this.props.hasError,
    })
    return (
      <label htmlFor={this.props.id} className={className}>
        {this.props.labelFirst && this.renderLabel()}
        {this.renderInput()}
        {!this.props.labelFirst && this.renderLabel()}
        {this.renderDescription()}
        {this.props.children}
      </label>
    )
  }
}
